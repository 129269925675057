// Import Core
import React from "react";
import styled from "styled-components";

const ButtonStyling = styled.button`
  -webkit-tap-highlight-color: transparent !important;
  &:focus,
  &:visited,
  &:active {
    outline: none !important;
  }
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 20px;
  /* height: 40px; */
  /* min-height: fit-content; */
  min-height: 40px;
  height: fit-content;
  min-width: 124px;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--blue);
  color: var(--white);
  border: none;
  transition: var(--basic-transition);
  font-family: var(--font-family-heading);
  &:hover {
    background-color: var(--red);
  }
  &:active {
    background: var(--red-110);
  }
  &:disabled {
    color: var(--grey);
    background: var(--grey-10);
    pointer-events: none;
    cursor: not-allowed;
  }
  &.sm {
    text-transform: capitalize;
  }
  &.outline {
    background-color: var(--white);
    color: var(--blue);
    border: 1px solid var(--blue);
    &:hover {
      background: var(--blue-10);
    }
    &:active {
      background: var(--blue-25);
    }
  }
  span {
    padding: 0;
    font-family: var(--font-family-heading);
  }
`;

const Button = ({
  size = "md",
  btnStyle = "default",
  className,
  value,
  clickHandler,
  isDisabled = false,
  type = "button",
}) => {
  const classes = className
    ? `${size} ${btnStyle} ${className}`
    : `${size} ${btnStyle}`;

  return (
    <ButtonStyling
      className={classes}
      onClick={clickHandler ? clickHandler : undefined}
      disabled={isDisabled}
      type={type}
    >
      <span>{value}</span>
    </ButtonStyling>
  );
};

export default Button;
