// Import Core
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
// Import Swiper Styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// Import Components
import Button from '../ui/buttons/Button';
// Import Utils
import { usePlaceholderImage } from '../../utils/useRandomPlaceholderImage';
import { getSlug } from '../../utils/getSlug';
import { toSlug } from '../../utils/search/toSlug';

const SmallCardCarouselStyling = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 40px;
	background: var(--white);
	color: var(--neutral-75);
	gap: 80px;
	@media (max-width: 992px) {
		/* padding: 64px 40px; */
		gap: 40px;
	}
	@media (max-width: 768px) {
		padding: var(--global-padding-mobile);
	}

	.section-title {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 24px;
		@media (max-width: 768px) {
			padding: 0;
			flex-direction: column;
			gap: 24px;
		}
		.content {
			display: flex;
			max-width: 768px;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
			@media (max-width: 768px) {
				gap: 20px;
			}
			.title {
				color: var(--black);
			}
		}
	}

	
	.swiper {
		position: relative;
		width: 100%;
		height: fit-content;
		display: flex;
		flex-direction: column;
		z-index: 0;
		padding: 0 24px;
		padding-bottom: calc(48px + 28px);
		@media (max-width: 768px) {
			padding: 0;
			padding-bottom: calc(32px + 28px);
		}
		/* Hide extra pagination padding if all slides fit screen - no pagination will show  */
		&:has(.swiper-button-next.swiper-button-lock) {
			padding-bottom: 0;
		}
		&:after {
			content: '';
			background: linear-gradient(
				270deg,
				var(--white) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			position: absolute;
			right: 0;
			width: 254px;
			height: 100%;
			z-index: 1;
			transition: var(--basic-transition);
			opacity: 1;
		}
		&:hover {
			&:after {
				opacity: 0;
				z-index: 0;
			}
		}
		.swiper-wrapper {
			.swiper-slide {
				height: auto;
				border-right: 1px solid var(--neutral-10);
				width: calc(205px + 32px);
				margin-right: 32px;
				@media (max-width: 768px) {
					width: calc(205px + 24px);
					margin-right: 24px;
				}
				&:last-child {
					margin-right: 0;
					border-right: none;
				}
				.card {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 24px;
					width: 205px;
					img {
						display: block;
						width: 100%;
						height: 200px;
						object-fit: cover;
					}
					.content {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 4px;
						.title {
							color: var(--black);
						}
					}
				}
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			background-color: var(--red);
			width: 48px;
			padding: 9px 0px;
			&:after {
				color: var(--white);
				font-size: 20px;
				font-style: normal;
				font-weight: 900;
				line-height: 150%;
			}
			@media (max-width: 768px) {
				top: unset;
				bottom: 0;
				z-index: 2;
			}
			&:hover {
				background-color: var(--red-110);
			}
			&.swiper-button-disabled {
				display: none;
			}
		}
		.swiper-button-prev,
		.swiper-rtl .swiper-button-next {
			left: 0;
		}
		.swiper-button-next,
		.swiper-rtl .swiper-button-prev {
			right: 0;
		}

		.swiper-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			padding: 8px 16px;
			margin: 0;
			z-index: 1;
			.swiper-pagination-bullet {
				width: 8px;
				height: 8px;
				border-radius: 0;
				opacity: 1;
				background-color: var(--neutral-50);
				&.swiper-pagination-bullet-active {
					background-color: var(--grey);
				}
			}
		}
	}
	// Awards Block
	&.red-bg {
		background-color: var(--red-10);
		gap: 64px;

		.swiper {
			.swiper-button-prev,
			.swiper-button-next {
				display: none;
			}
		}
		.awards {
			&.swiper-button-prev,
			&.swiper-button-next {
				background-color: var(--red);
				width: 48px;
				padding: 9px 0px;
				&:after {
					color: var(--white);
					font-size: 20px;
					font-style: normal;
					font-weight: 900;
					line-height: 150%;
				}
				@media (max-width: 768px) {
					top: unset;
					bottom: 0;
					z-index: 2;
				}
				&:hover {
					background-color: var(--red-110);
				}
				&.swiper-button-disabled {
					display: none;
				}
			}
			&.swiper-button-prev,
			&.swiper-rtl .swiper-button-next {
				top: 80px;
				left: unset;
				right: calc(48px + 24px + 40px);
				@media (max-width: 768px) {
					top:unset;
					left: 20px;
					right: unset;
					bottom: 40px;
				}
			}
			&.swiper-button-next,
			&.swiper-rtl .swiper-button-prev {
				top: 80px;
				right: 40px;
				@media (max-width: 768px) {
					top:unset;
					right: 20px;
					bottom: 40px;
				}
			}

		}
	}
`;

const CardContent = ({ title, content, image }) => {
	const { randomPlaceholderImage } = usePlaceholderImage();

	return (
		<div className="card">
			{image && image.asset ? (
				<GatsbyImage image={getImage(image.asset)} alt={title || content} />
			) : (
				<img
					src={randomPlaceholderImage}
					alt={title}
					className="placeholder-img"
				/>
			)}

			<div className="content">
				<h5 className="alt title">{title}</h5>
				<p>{content}</p>
			</div>
		</div>
	);
};

const SmallCardCarousel = ({
	addToAnchor,
	title,
	content,
	linkText,
	link,
	cardList,
	styleType=''
}) => {
	// If a link comes from config file it will be a string e.g. Meet the team
	const slugPath =
		link && link.link
			? typeof link === 'string'
				? link
				: link.link.slug.current
			: null;

	return (
		<SmallCardCarouselStyling
			id={addToAnchor ? `anchor_${toSlug(title)}` : ''}
			className={`small-card-carousel ${addToAnchor && 'anchor-link'} ${styleType}`}>
			<div className="section-title">
				<div className="content">
					<h2 className="title">{title}</h2>
					<p>{content}</p>
				</div>
				{slugPath && (
					<Link to={slugPath}>
						<Button
							size="md"
							btnStyle="outline"
							className=""
							value={linkText}
							clickHandler={false}
							isDisabled={false}
						/>
					</Link>
				)}
			</div>

			<Swiper
				slidesPerView={'auto'}
				spaceBetween={0}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Pagination, Navigation]}
				className="card-carousel-swiper">
				{cardList.map((card, index) => (
					<SwiperSlide key={index}>
						{card.slug && card.slug.current ? (
							<Link to={getSlug(card)}>
								<CardContent
									title={card.title}
									content={card.content}
									image={card.image}
								/>
							</Link>
						) : (
							<CardContent
								title={card.title}
								content={card.content}
								image={card.image}
							/>
						)}
					</SwiperSlide>
				))}
			</Swiper>
			{styleType === 'red-bg' && (
				<>
					<div className="awards swiper-button-prev"></div>
					<div className="awards swiper-button-next"></div>
				</>
			)}		
		</SmallCardCarouselStyling>
	);
};

export default SmallCardCarousel;
