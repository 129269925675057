// Import Core
import React from "react";
import styled from "styled-components";
// Import Utils
import { renderReactIcon } from "../../utils/renderReactIcons";

const SocialIconsStyling = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  svg {
    font-size: 20px;
  }
`;

const SocialIcons = ({ socialLinks }) => {
	return (
		<SocialIconsStyling className='social-icons'>
      {socialLinks.map((link) => {
				const Icon = renderReactIcon(link.socialIcon.name);
				return (
					<a
						key={link._key}
						href={
							link.navItem.navItemUrl.displayExternal
								? link.navItem.navItemUrl.externalUrl
								: link.navItem.navItemUrl.internalUrl
						}
						target='_blank'
						className='social-icon small'
						aria-label={link.socialIcon.name}
					>
						{Icon}
					</a>
				);
			})}
		</SocialIconsStyling>
	);
};

export default SocialIcons;