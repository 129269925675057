// Import Core
import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
// Import Libraries
import { useForm } from 'react-hook-form';
import axios from 'axios';
// Import Components
import Loader from '../Loader';
import Button from '../../ui/buttons/Button';

/* Styled Components */
const NewsletterFormStyling = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		gap: 16px;
		.form-group {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			gap: 16px;
			@media (max-width: 768px) {
				flex-direction: column;
			}
			input {
				max-width: unset;
			}
			.desktop-submit-btn {
				min-width: fit-content;
				display: block;
				@media (max-width: 768px) {
					display: none;
				}
			}
			.input-group {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 16px;
				.email-label {
					width: 100%;
				}
				.name-group {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 16px;
					@media (max-width: 768px) {
						flex-direction: column;
						align-items: flex-start;
					}
				}
			}
		}
		.mobile-submit-btn {
			min-width: fit-content;
			display: none;
			@media (max-width: 768px) {
				display: block;
			}
		}
		.consent {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 12px;
		}
	}
`;

const NewsletterForm = () => {
	// Initiate forms
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm();

	// Handle form submit
	const handlePost = async (formData) => {
		// Send data to sanity
		const api =
			'https://4nzec7n6y8.execute-api.eu-west-2.amazonaws.com/default/kirkerGatsbyFormSubmission';
		axios
			.post(api, { formType: 'new-form', body: formData })
			.then((response) => {
				console.log('response', response);
				// setLoading(false);
				navigate('/form-confirmation/?type=newsletter&status=success');
			})
			.catch((error) => {
				console.log(error);
				// setLoading(false);
				navigate('/form-confirmation/?type=newsletter&status=fail');
			});
	};

	return (
		<NewsletterFormStyling>
			{isSubmitting ? (
				<Loader />
			) : (
				<form
					onSubmit={handleSubmit(handlePost)}
					name='newsletter-form'
					id='newsletter-form'
					method='POST'
					honeypot='got-ya'>
					<input type='hidden' name='form-name' value='newsletter-form' />
					<input
						type='hidden'
						name='formId'
						value='newsletter-form'
						{...register('formId')}
					/>

					<div className='form-group'>
						<div className='input-group'>
							<div className='name-group'>
								<input
									type='text'
									placeholder='First name'
									autoComplete='off'
									{...register('firstName')}
								/>
								<input type='text' placeholder='Last name' autoComplete='off' 
								{...register('lastName')} 
								/>
							</div>

							<label htmlFor='email' className='email-label'>
								{/* <p>Email address</p> */}
								{errors && errors.email && (
									<p className='error-msg' role='alert'>
										Please complete this field
									</p>
								)}
								<input
									type='email'
									name='email'
									placeholder='Enter your email address'
									{...register('email', {
										required: true,
										pattern:
											/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
									})}
								/>
							</label>
						</div>
						<Button
							size='md'
							btnStyle='default'
							className='desktop-submit-btn'
							value={'Subscribe'}
							clickHandler={false}
							isDisabled={false}
							type='submit'
						/>
					</div>

					<label className='consent' htmlFor='consent'>
						{errors && errors.consent && (
							<p className='error-msg' role='alert'>
								Please accept the privacy policy.{' '}
							</p>
						)}
						<input
							type='checkbox'
							name='consent'
							{...register('consent', {
								required: true,
							})}
						/>
						<p className='small'>
							By clicking Sign Up you're confirming that you agree with our{' '}
							<Link to='/privacy-policy'>Terms and Conditions</Link>.
						</p>
					</label>

					<Button
						size='md'
						btnStyle='default'
						className='mobile-submit-btn'
						value={'Subscribe'}
						clickHandler={false}
						isDisabled={false}
						type='submit'
					/>

					{/* HONEYPOT */}
					<label
						htmlFor='got-ya'
						style={{
							position: 'absolute',
							overflow: 'hidden',
							clip: 'rect(0 0 0 0)',
							height: '1px',
							width: '1px',
							margin: '-1px',
							padding: '0',
							border: '0',
						}}>
						Don't fill this out if you're human:
						<input tabIndex='-1' name='got-ya' {...register('got-ya')} />
					</label>
				</form>
			)}
		</NewsletterFormStyling>
	);
};

export default NewsletterForm;
