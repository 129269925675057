import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaPlane, FaConciergeBell, FaCheck, FaLinkedin, FaCar, FaShip, FaCarSide, FaTrain, FaUtensils, FaGift, FaBookOpen } from 'react-icons/fa';
import { FaFerry, FaCarTunnel, FaTicketSimple, FaRegCompass, FaBuildingColumns, FaWineGlass, FaUser } from "react-icons/fa6";
import { SiX } from "react-icons/si";
import { MdEmojiTransportation, MdBed } from 'react-icons/md';
// MdEmojiTransportation
// PhoneFill
// MdEmail
// MdLocationPin
// HiClock

export const renderReactIcon = element => { 
  let icon = <FaCheck />;
  switch(element) {
    case 'FaCheck':
      icon = <FaCheck />
    break;
    case 'FaFacebook':
      icon = <FaFacebookF />
    break;
    case 'FaLinkedin':
      icon = <FaLinkedin />
    break;
    case 'FaInstagram':
      icon = <FaInstagram />
    break;
    case 'FaYoutube':
      icon = <FaYoutube />
    break;
    case 'FaPlane':
      icon = <FaPlane />
    break;
    case 'FaConciergeBell':
      icon = <FaConciergeBell />
    break;
    case 'SiX':
      icon = <SiX />
    break;
    case 'FaCar':
      icon = <FaCar />
    break;
    case 'FaShip':
      icon = <FaShip />
    break;
    case 'FaCarSide':
      icon = <FaCarSide />
    break;
    case 'FaTrain':
      icon = <FaTrain />
    break;
    case 'FaGift':
      icon = <FaGift />
    break;
    case 'MdBed':
      icon = <MdBed />
    break;
    case 'MdEmojiTransportation':
      icon = <MdEmojiTransportation />
    break;
    case 'FaFerry':
      icon = <FaFerry />
    break;
    case 'FaCarTunnel':
      icon = <FaCarTunnel />
    break;
    case 'FaUtensils':
      icon = <FaUtensils />
    break;

    case 'FaTickeAlt':
      icon = <FaTicketSimple />
    break;

    case 'FaBookOpen':
      icon = <FaBookOpen />
    break;

    case 'FaRegCompass':
      icon = <FaRegCompass />
    break;

    case 'FaBuildingColumns':
      icon = <FaBuildingColumns />
    break;

    case 'FaWineGlass':
      icon = <FaWineGlass />
    break;

    case 'FaUser':
      icon = <FaUser />
    break;

    default:
      icon = <FaCheck />
    break;
  }
  return icon;
}