// Import Core
import React from 'react'
import styled from 'styled-components';
// Import Assets
import separatorRed from '../../assets/images/separators/separator-red.svg';
import separatorYellow from '../../assets/images/separators/separator-yellow.svg';
import separatorGreyThick from '../../assets/images/separators/separator-grey-thick.svg';
import separatorGreyThin from '../../assets/images/separators/separator-grey-thin.svg';


const SeparatorStyling = styled.div`
  width: 100%;
  &.red {
    height: 10px;
    background: url(${separatorRed});
  }
  &.yellow {
    height: 10px;
    background: url(${separatorYellow});
  }
  &.grey-thick {
    height: 2px;
    background: url(${separatorGreyThick});
  }
  &.grey-thin {
    height: 1px;
    background: url(${separatorGreyThin});
  }
`;

const Separator = ({ color }) => {
  // let image = separatorRed;
  // if(color === 'yellow') {
  //   image = separatorYellow;
  // } else if(color === 'grey-thick') {
  //   image = separatorGreyThick
  // } else if(color === 'grey-thin') {
  //   image = separatorGreyThin
  // }

  return (
    <SeparatorStyling className={`separator ${color}`} />
  )
}

export default Separator