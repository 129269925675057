// Import Core
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';
// Import Utils
import useTablet from '../../../utils/useTablet';
import useMobile from '../../../utils/useMobile';
import { calculateOpeningTimes } from '../../../utils/datetime/calculateOpeningTimes';
// Import configData
import configData from '../../../../configData.json';

const MobileBannerStyling = styled.div`
	background-color: var(--blue-10);
	padding: 8px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: none;
	border-top: 1px solid var(--neutral-10);
	padding-top: 12px;
	gap: 4px;
	position: sticky;
	z-index: 998;
	.phone {
		* {
			color: var(--blue);
			font-size: 18px;
		}
	}
	.open {
		color: var(--red);
	}
	@media (max-width: 992px) {
		display: flex;
	}
`;

const MobileBanner = ({ className }) => {
	const [displayOpen, setDisplayOpen] = useState('');
	useEffect(() => {
		calculateOpeningTimes(setDisplayOpen);
	}, []);

	const { isTablet } = useTablet();
	const { isMobile } = useMobile();

	// Calculate height for hamburger
	const handleResize = () => {
		const height = document.getElementsByTagName('header')[0].clientHeight;

		if (isTablet || isMobile) {
			document.querySelectorAll('.mobile-banner')[0].style.top = height + 'px';
		}
	};
	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize, false);
	}, [isTablet, isMobile]);

	return (
		<MobileBannerStyling className={className}>
			<div className='phone'>
				<a href={configData.enquiry.phone.link}>
					<p>{configData.enquiry.phone.alt_text}</p>
				</a>
			</div>

			<div className='open'>
				<p className='small'>{displayOpen}</p>
				{/* Temp to show easter opening times */}
				{/* <p><Link to="/easter-opening-times">Easter Opening Times</Link></p> */}
			</div>
		</MobileBannerStyling>
	);
};

export default MobileBanner;
