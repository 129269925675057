// Import Core
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SponsorsStyling = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 32px;
	width: fit-content;
	/* hr {
		border: 1px solid var(--neutral-10);
		width: 100%;
	} */
	> div {
		display: flex;
		flex-direction: column;
		gap: 24px;
		.sponsors-title {
			color: var(--grey);
			text-align: center;
		}
		.sponsors-list {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 32px;
		}
	}
	&.dark {
		gap: 40px;
		> div {
			.sponsors-title {
				color: var(--red-25);
			}
		}
		.trusted {
			.sponsors-title {
				display: none;
			}
		}
		hr {
			display: none;
		}
	}
`;

const renderSponsors = (sponsor, color) => {
	return sponsor.map((item, index) => {
		return color === 'default' ? (
			<GatsbyImage
				image={getImage(item.lightImage.asset)}
				alt={item.altText}
				key={index}
			/>
		) : (
			<GatsbyImage
				image={getImage(item.darkImage.asset)}
				alt={item.altText}
				key={index}
			/>
		);
	});
};

const Sponsors = ({ color = 'default' }) => {
	const data = useStaticQuery(graphql`
		query SponsorsQuery {
			sanitySiteSettings {
				trustedBySection {
					altText
					lightImage {
						asset {
							gatsbyImageData(height: 40, layout: FIXED)
						}
					}
					darkImage {
						asset {
							gatsbyImageData(height: 40, layout: FIXED)
						}
					}
				}
				partnerWithSection {
					altText
					lightImage {
						asset {
							gatsbyImageData(height: 30, layout: FIXED)
						}
					}
					darkImage {
						asset {
							gatsbyImageData(height: 30, layout: FIXED)
						}
					}
				}
			}
		}
	`);

	return (
		<SponsorsStyling className={color}>
			<div className='trusted'>
				<h6 className='sponsors-title'>Trusted By</h6>
				<ul className='sponsors-list'>
					{renderSponsors(data.sanitySiteSettings.trustedBySection, color)}
				</ul>
			</div>
			<hr />
			<div className='partner'>
				<h6
					className={`sponsors-title${color === 'dark' ? ' alt italic' : ''}`}>
					Partnered With
				</h6>
				<ul className='sponsors-list'>
					{renderSponsors(data.sanitySiteSettings.partnerWithSection, color)}
				</ul>
			</div>
		</SponsorsStyling>
	);
};

export default Sponsors;
