// Import Core
import React from 'react';
import { Link, navigate } from 'gatsby';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// Import Components
import TextButton from '../components/ui/buttons/TextButton';
// Import Utils
import { getSlug } from '../utils/getSlug';
import { usePlaceholderImage } from './useRandomPlaceholderImage';

const getNavItemLinks = (navItem) => {
	const displayExternalLink = navItem.navItemUrl.displayExternal;
	const internalLink = navItem.navItemUrl.internalLink;
	const externalUrl = navItem.navItemUrl.externalUrl;
	// Depending on displayExternal checkbox link will be internal or external
	const link = displayExternalLink ? externalUrl : internalLink;

	return {
		displayExternalLink: displayExternalLink,
		link: link,
	};
};

// If Get Links is to return a text button
export const GetTextButtonLinks = ({ navItem, className, size }) => {
	const { displayExternalLink, link } = getNavItemLinks(navItem);
	if (navItem.name && link) {
		// Two options:
		// 1. Internal Link
		if (!displayExternalLink && link !== null) {
			const slug = getSlug(link);
			return (
				<TextButton
					clickHandler={() => {
						navigate(slug);
					}}
					className={className}
					value={navItem.name}
					size={size}
				/>
			);
		}
		// 2. External Link
		else if (displayExternalLink === true && link !== null) {
			return (
				<a href={link} target='_blank'>
					<TextButton
						clickHandler={false}
						className={className}
						value={navItem.name}
						size={size}
					/>
				</a>
			);
		}
	} else {
		return null;
	}
};

// If Get Links is to return a button
// export const GetLinks = ({ navItem, buttonStyle, location }) => {
// console.log("🚀 ~ file: getNavItemLinks.js:30 ~ GetLinks ~ navItem:", navItem);

// 		const {displayExternalLink, link} = getNavItemLinks(navItem);
// 			console.log({displayExternalLink, link});
// 			if(navItem.name && link) {
// 				// Two options:
// 				// 1. Internal Link
// 				if (!displayExternalLink && link !== null) {
// 					const slug = getSlug(link)
// 					console.log("🚀 ~ file: getNavItemLinks.js:39 ~ GetLinks ~ slug:", slug);
// 					return(
// 						<button
// 							onClick={()=>{navigate(slug)}}
// 							role="link"
// 							className={buttonStyle}
// 						>
// 							{navItem.name}
// 						</button>
// 					)
// 				}
// 				// 2. External Link
// 				else if (displayExternalLink === true && link !== null) {
// 					console.log("🚀 ~ file: getNavItemLinks.js:52 ~ GetLinks ~ link:", link);
// 					return(
// 						<a href={link} target='_blank'>
// 							<button
// 								className={buttonStyle}
// 							>
// 								{navItem.name}
// 							</button>
// 						</a>
// 					)
// 				}
// 			} else {
// 				return null
// 			}
// 		}


export const getLinks = (navItem) => {
	if (navItem && navItem.navItemUrl) {
		const { displayExternalLink, link } = getNavItemLinks(navItem);
		if (navItem.name && link) {
			// Two options:
			// 1. Internal Link
			if (!displayExternalLink && link !== null) {
				const slug = getSlug(link);
				return {
					link: slug,
					text: navItem.name,
					type: 'internal',
				};
			}
			// 2. External Link
			else if (displayExternalLink === true && link !== null) {
				return {
					link: link,
					text: navItem.name,
					type: 'external',
				};
			}
		} else {
			return null;
		}
	} else return null;
};

// If navItems is a list item return this
export const GetListLinks = ({ item, index, isMainMenu, setNavbarOpen, handleClick }) => {
	let displayExternalLink, link;
	if (item.navItemUrl) {
		displayExternalLink = getNavItemLinks(item).displayExternalLink;
		link = getNavItemLinks(item).link;
	} else {
		link = item;
	}
	
	const newSlug = typeof link === 'object'  ? getSlug(link) : null;

	const handleLocalClick = () => {
		setNavbarOpen(false);
	};

	// Two options:
	// 1. Internal Link
	if (displayExternalLink === false && newSlug !== null) {
		return (
			<li key={index} onClick={isMainMenu ? handleLocalClick : handleClick}>
				<Link to={newSlug}>
					<p>{item.name}</p>
				</Link>
			</li>
		);
	}
	// 2. External Link
	else if (displayExternalLink === true && link !== null) {
		return (
			<li key={index} onClick={handleClick}>
				<a href={link} target='_blank'>
					<p>{item.name}</p>
				</a>
			</li>
		);
	} else {
		return (
			<li key={index} onClick={handleClick}>
				<Link to={newSlug}>
					<p>{item.name}</p>
				</Link>
			</li>
		);
	}
};

// If navItems is a detailed list item
export const GetDetailedListLinks = ({ item, className, index, handleClick }) => {
	let displayExternalLink, link;
	if (item.navItemUrl) {
		displayExternalLink = getNavItemLinks(item).displayExternalLink;
		link = getNavItemLinks(item).link;
	} else {
		link = item;
	}
	const newSlug = getSlug(link);
	const { randomPlaceholderImage } = usePlaceholderImage(); 

	// Two options:
	// 1. Internal Link
	if (displayExternalLink === false && link !== null) {
		const pageType = item.navItemUrl.internalLink._type.replace('Page', '');
		return (
			<li key={index} className={className} onClick={handleClick}>
				<Link to={newSlug}>
					{item.navItemUrl.internalLink.thumbnail ? (
						<GatsbyImage
							image={getImage(item.navItemUrl.internalLink.thumbnail.asset)}
							alt={item.name}
						/>
					) : (
						<img
							src={randomPlaceholderImage}
							alt={item.name}
							className="placeholder-img"
						/>
					)}
					<div className='detailed-list-item-content'>
						<p className='tagline'>{pageType}</p>
						<h5>{item.name}</h5>
					</div>
				</Link>
			</li>
		);
	}
	// 2. External Link
	else if (displayExternalLink === true && link !== null) {
		return (
			<li key={index} onClick={handleClick}>
				<a href={link} target='_blank'>
					<p>{item.name}</p>
				</a>
			</li>
		);
	} else {
		return (
			<li key={index} onClick={handleClick}>
				<Link to={newSlug}>
					<p>{item.name}</p>
				</Link>
			</li>
		);
	}
};

export default getNavItemLinks;