// Import Core
import { useEffect, useState } from 'react';

const useMobile = () => {
	const [isMobile, setIsMobile] = useState(
		typeof window !== 'undefined' && window.innerWidth < 768
	);

	function handleResize() {
		setIsMobile(window.innerWidth < 768);
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			handleResize();
		}

		window.addEventListener('resize', handleResize);
		return () => {
			// remove event listener when the component is unmounted to not cause any memory leaks
			// otherwise the event listener will continue to be active
			window.removeEventListener('resize', handleResize);
		};
		// add `isMobile` state variable as a dependency so that
		// it is called every time the window is resized
	}, [isMobile]);

	return { isMobile, handleResize };
};

export default useMobile;
