// Import Core
import React, { useState } from "react";
import styled from "styled-components";
// Import Components
import HamburgerMenu from "./headerNavigation/HamburgerMenu";
import NavItem from "./headerNavigation/NavItem";

const NavStyling = styled.div`
  width: 100%;
  .navigation {
    width: 100%;
    .navbar {
      z-index: 999;
      max-width: fit-content;
      margin-left: auto;
      @media (max-width: 992px) {
        display: none;
      }
      .navbar-menu {
        display: flex;
        align-items: center;
        gap: 24px;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
  }
`;

const Navbar = ({
  menu,
  displayList,
  isActiveNavItem,
  activeIndex,
  handleClick,
}) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const closeHamburger = () => {
    setHamburgerOpen(false);
  };

  /*********************
   * TODO:
   * * displayList? = true for navmenu
   */

  return (
    <NavStyling>
      <div className="navigation" aria-labelledby="menu">
        {/* Mobile Menu */}
        <HamburgerMenu
          isOpen={hamburgerOpen}
          menu={menu}
          isActiveNavItem={isActiveNavItem}
          activeIndex={activeIndex}
          handleClick={handleClick}
          toggleHamburger={toggleHamburger}
		  closeHamburger={closeHamburger}
        />

        {/* Mega Menu */}
        <nav className="navbar">
          <ul className="navbar-menu">
            {menu.length > 0 &&
              menu.map((menuItem, index) => {
                return (
                  <NavItem
                    key={index}
                    menuItem={menuItem}
                    isActiveNavItem={isActiveNavItem}
                    index={index}
                    activeIndex={activeIndex}
                    handleClick={handleClick}
					closeHamburger={closeHamburger}
                  />
                );
              })}
          </ul>
        </nav>
      </div>
    </NavStyling>
  );
};

export default Navbar;
