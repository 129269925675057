// Impport Core
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// Import Components
import IconButton from '../../ui/buttons/IconButton';
import MenuSubMenuItem from './MenuSubMenuItem';
import MobileMenuBanner from '../banners/MobileMenuBanner';
// Import Assets
import { LiaTimesSolid, LiaBarsSolid } from 'react-icons/lia';
// Import Utils
import { getLinks } from '../../../utils/getNavItemLinks';
import useTablet from '../../../utils/useTablet';
import useMobile from '../../../utils/useMobile';

const HamburgerStyling = styled.div`
	.hamburger {
		display: none;
		z-index: 999;
		svg {
			background-color: var(--white);
			color: var(--black);
			/* width: 32px;
			height: 32px; */
			padding: 8px;
		}
		@media (max-width: 992px) {
			display: flex;
			z-index: 2;
		}
		&.open + .mobile-navbar {
			background: var(--white);
			opacity: 1;
			z-index: 1;
			left: 0;
			height: 100%;
			width: 100%;
			padding: 20px;
			display: flex;
			flex-direction: column;
			gap: 40px;
		}
	}
	.mobile-navbar {
		display: none;
		@media (max-width: 992px) {
			display: block;
			width: 0;
			opacity: 0;
			right: 0;
			position: fixed;
			/* transition: var(--basic-transition); */
			padding: 0;
			.mobile-menu {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 4px;
				.mobile-menu-list-item {
					padding: 12px 0;
					border-bottom: 1px solid var(--neutral-10);
					text-transform: uppercase;
					a{
						font-family: var(--font-family-heading);
					}
				}
			}
		}
	}
`;

const HamburgerMenu = ({
	isOpen,
	menu,
	isActiveNavItem,
	activeIndex,
	handleClick,
	toggleHamburger,
	closeHamburger
}) => {
	const { isTablet } = useTablet();
	const { isMobile } = useMobile();

	// Calculate height for hamburger
	const handleResize = () => {
		const height = document.getElementsByTagName('header')[0].clientHeight;

		if (isTablet || isMobile) {
			// Take height of header & height of searchbar
			const calcHeight = height - 72;
			document.querySelectorAll('.mobile-navbar')[0].style.top = height + 'px';
			if (document.querySelectorAll('.hamburger.open+.mobile-navbar')[0]) {
				document.querySelectorAll(
					'.hamburger.open+.mobile-navbar'
				)[0].style.height = "calc(100vh - " + calcHeight + "px)";
			}
		}
	};
	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize, false);
	}, [isTablet, isMobile, isOpen]);

	return (
		<HamburgerStyling>
			<IconButton
				className={isOpen ? 'hamburger open' : 'hamburger'}
				ariaLabel={isOpen ? 'Close Navigation Menu' : 'Show Navigation Menu'}
				ariaExpanded={isOpen ? 'true' : 'false'}
				clickHandler={toggleHamburger}
				isDisabled={false}>
				{isOpen ? <LiaTimesSolid /> : <LiaBarsSolid />}
			</IconButton>

			<nav className='mobile-navbar'>
				<div className='mobile-menu'>
					{menu.map((item, index) => {
						const singleLink = getLinks(item.items[0]);

						return (
							<div key={index}>
								{item.items.length === 1 ? (
									<p className='mobile-menu-list-item'>
										{singleLink.type === 'internal' ? (
											<Link 
												to={singleLink.link}
												onClick={toggleHamburger}
											>{item.subMenuName}</Link>
										) : (
											<a
												href={singleLink.link}
												target='_blank'
												rel='noreferrer' 
												onClick={toggleHamburger}>
												{item.subMenuName}
											</a>
										)}
									</p>
								) : (
									<>
										<MenuSubMenuItem
											key={index}
											title={item.subMenuName}
											items={item}
											detailedSubMenu={item.detailedSubMenu}
											sectionLink={item.subMenuLink}
											sectionContent={item.subMenuCaption}
											isActiveNavItem={isActiveNavItem}
											index={index}
											activeIndex={activeIndex}
											handleClick={handleClick}
											closeHamburger={closeHamburger}	
											className='mobile-menu-list-item'
										/>
									</>
								)}
							</div>
						);
					})}
				</div>
				<MobileMenuBanner />
			</nav>
		</HamburgerStyling>
	);
};

export default HamburgerMenu;
