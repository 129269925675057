// Filter through flattened object & return results that match inputted search word
const filterPagesFromSearchValue = (arr, searchKey) =>
  arr.filter((obj) =>
    Object.keys(obj).some((key) => {
      if (obj[key] && typeof obj[key] === "string") {
        // Split words entered into an array so we can check if all words match an item - if so then we can return the result
        let searchValues = searchKey.split(" ");
        let found = searchValues.every((item) =>
          obj[key].toLowerCase().includes(item.toLowerCase())
        );
        if (found) {
          // console.log('match!', {searchValues}, obj[key])
          return obj[key];
        }
        // return obj[key].includes(searchKey.toLowerCase())
      }
    })
  );

const getFlattenedProducts = (pageType) => {
  return pageType.map((page) => {
    return {
      _id: page._id, 
      _type: page._type,
      title: page.title,
      slug: page.slug.current,
      // excerpt: page.excerpt ? page.excerpt : page.content,
      thumbnail: page.thumbnail?.asset,
      includes:
        page.includes?.map((tag) => tag.name.toLowerCase()).toString() || "",
      city: page.city?.name,
      country: page.country?.name,
      includedCities:
        page.includedCities?.map((tag) => tag.name.toLowerCase()).toString() ||
        "",
      includedCountries:
        page.includedCountries
          ?.map((tag) => tag.name.toLowerCase())
          .toString() || "",
      // themeTags: page.themeTags,
      themeTags:
        page.themeTags?.themeTags?.tag
          ?.map((tag) => tag.label.toLowerCase())
          .toString() || "",
      hotelType: page.hotelType,
      starRating: page.starRating,
    };
  });
};

const getFlattenedLocations = (pageType) => {
  return pageType.map((page) => {
    return {
      _id: page._id,
      _type: page._type,
      isInEurope: page.isInEurope,
      title: page.name,
      slug: page.slug.current,
      // excerpt: page.excerpt ? page.excerpt : page.content,
      thumbnail: page.thumbnail?.asset,
      // city: page.city?.name,
      country: page.country?.name,
    };
  });
};

const getFlattenedPages = (pageType) => {
  return pageType.map((page) => {
    return {
      _id: page._id,
      _type: page._type,
      title: page.name,
    };
  });
};

export {
  filterPagesFromSearchValue,
  getFlattenedProducts,
  getFlattenedLocations,
  getFlattenedPages,
};
