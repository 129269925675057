// Import Core
import React from "react";
import styled from "styled-components";

// TODO:  Change Loader Style
const LoaderStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;

  .loader-18 {
    position: relative;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      border: .15em solid transparent;
      border-bottom-color: var(--red);
      top: 0;
      left: 0;
      animation: 1s loader-18 linear infinite;
    }
    &:before {
      width: 2em;
      height: 2em;
    }
    &:after {
      width: 1.6em;
      height: 1.6em;
      top: .2em;
      left: .2em;
      animation-direction: reverse;
    }
  }

  @keyframes loader-18 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

console.log('loader')

const Loader = () => {
  return (
    <LoaderStyles>
      <div className="loader-18"></div>
    </LoaderStyles>
  );
};

export default Loader;
