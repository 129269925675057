// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Assets
import { FaChevronRight } from 'react-icons/fa';

const TextButtonStyling = styled.button`
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	cursor: pointer;
	padding: 0;
	height: 24px;
	min-height: fit-content;
	text-align: left;
	text-transform: uppercase;
	background-color: transparent;
	color: var(--black);
	width: fit-content;
	border: none;
	.btn-icon {
		background-color: var(--blue);
		color: var(--white);
		font-size: var(--body-font-size);
		width: 24px;
		height: inherit;
		padding: 6px 4px;
		transition: var(--basic-transition);
	}
	&:hover {
		.btn-icon {
			background-color: var(--red);
		}
	}
	&:active {
		.btn-icon {
			background: var(--red-110);
		}
	}
	&:disabled {
		color: var(--grey-50);
		pointer-events: none;
		cursor: not-allowed;
		.btn-icon {
			background: var(--grey-10);
		}
	}
	&.sm {
		span {
			font-size: 0.778rem; //14px
		}
	}
	span {
		padding: 0;
		font-family: var(--font-family-heading);
	}
`;

const TextButton = ({
	size = 'md',
	className,
	value,
	clickHandler,
	isDisabled = false,
}) => {
	const classes = className ? `${size} ${className}` : size;
	return (
		<TextButtonStyling
			className={classes}
			onClick={clickHandler ? clickHandler : undefined}
			disabled={isDisabled}>
			<span>{value}</span>
			<FaChevronRight className='btn-icon' />
		</TextButtonStyling>
	);
};

export default TextButton;
