// Import Core
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// Import Components
import Button from '../../ui/buttons/Button';
// Import Utils
import { usePlaceholderImage } from '../../../utils/useRandomPlaceholderImage';


/* Styled Components */
const CTAStyling = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 64px;
	background-color: var(--white);
	color: var(--neutral-75);
	gap: 80px;
	@media (max-width: 992px) {
		flex-direction: column;
		gap: 40px;
	}
	@media (max-width: 768px) {
		padding: 32px 20px;
	}
	.cta-content {
		display: flex;
		flex-direction: column;
		gap: 40px;
		width: 60%;
		@media (max-width: 1100px) {
			width: 50%;
		}
		@media (max-width: 992px) {
			width: 100%;
		}
		@media (max-width: 768px) {
			gap: 24px;
		}
		.content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			@media (max-width: 768px) {
				gap: 20px;
			}
			h2.alt {
				text-transform: initial;
				font-weight: 400;
			}
		}
		button {
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
	.cta-image {
		width: 40%;
		height: 400px;
		@media (max-width: 1100px) {
			width: 50%;
		}
		@media (max-width: 992px) {
			width: 100%;
			height: 300px;
		}
	

		@media (max-width: 768px) {
        width: 100%;
        height: 250px;
      }
		/* flex: 1 0 0;
      height: inherit;
      @media (max-width: 1100px) {
        width: 40%;
        flex: unset;
      }
      @media (max-width: 992px) {
        width: 50%;
      }
       */
      .gatsby-image-wrapper, img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: inherit;
      }
      .placeholder-img {
        object-fit: contain;
      }
    
	}
`;

/* Render Component */
const Cta = ({ title, content, link, linkText, image }) => {
  const { randomPlaceholderImage} = usePlaceholderImage()

	return (
		<CTAStyling>
			<div className='cta-content'>
				<div className="content">
					<h2 className='alt red'>{title}</h2>
					<p className="large">{content}</p>
				</div>
				<Link to={link}>
					<Button
						size='md'
						btnStyle='default'
						value={linkText}
						clickHandler={false}
						isDisabled={false}
					/>
				</Link>
			</div>
			<div className='cta-image'>
				{image ? (
            <GatsbyImage
              image={getImage(image)}
              alt='Kirker Holidays'
            />
          ) : (
            <img src={randomPlaceholderImage} alt="" className="placeholder-img" />
          )}
			</div>
		</CTAStyling>
	);
};

export default Cta;
