// Import Core
import React from 'react';
import styled from 'styled-components';

const IconButtonStyling = styled.button`
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	cursor: pointer;
	padding: 0;
	/* height: 48px;
  width: 48px; */
	border: none;
	font-family: var(--font-family-heading);
	svg {
		background-color: var(--red);
		color: var(--white);
		font-size: var(--body-font-size);
		width: 48px;
		height: 48px;
		padding: 12px 6px;
		transition: var(--basic-transition);
	}
	&:hover {
		svg {
			background-color: var(--red-110);
		}
	}
	&:active {
		svg {
			background: var(--red-110);
		}
	}
	&:disabled {
		pointer-events: none;
		cursor: not-allowed;
		svg {
			background: var(--neutral-25);
		}
	}
`;

const IconButton = ({
	className,
	clickHandler = undefined,
	isDisabled = false,
	children,
	ariaLabel,
	ariaExpanded,
}) => {
	return (
		<IconButtonStyling
			className={className}
			onClick={clickHandler ? clickHandler : undefined}
			disabled={isDisabled}
			aria-label={ariaLabel}
			aria-expanded={ariaExpanded}>
			{children}
		</IconButtonStyling>
	);
};

export default IconButton;
