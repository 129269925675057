// Import Core
import { useState } from 'react';

const usePopover = () => {
	const [isPopoverActive, setIsPopoverActive] = useState(false);
	const [activePopoverId, setActivePopoverId] = useState(undefined);

	const togglePopover = (id) => {
		if (activePopoverId === id) {
			setActivePopoverId(null);
			setIsPopoverActive(false);
		} else {
			setIsPopoverActive(true);
			setActivePopoverId(id);
		}
		if (activePopoverId === null) {
			setActivePopoverId(id);
			setIsPopoverActive(true);
		}
	};

	return [isPopoverActive, activePopoverId, togglePopover];
};

export default usePopover;
