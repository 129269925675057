// Import Core
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// Import Styles
import Typography from '../styles/Typography';
import GlobalStyles from '../styles/GlobalStyles';
// Import Component
import Header from './layout/Header';
import Footer from './layout/Footer';
import SearchBar from './layout/SearchBar';
import MobileBanner from './layout/banners/MobileBanner';
import EnquiryCard from './blocks/EnquiryCard';
import NewsletterCta from './blocks/ctas/NewsletterCta';
import Cta from './blocks/ctas/Cta';
import SmallCardCarousel from './blocks/SmallCardCarousel';
import FloatingButton from './ui/buttons/FloatingButton';
import Popover from './ui/Popover';
// Import Utils
import usePopover from '../utils/usePopover';
// Import configData
import configData from '../../configData.json';
import Separator from './ui/Separator';

const Layout = ({ children }) => {
	const [isPopoverActive, activePopoverId, togglePopover] = usePopover();
	const [searchValue, setSearchValue] = useState('');

	const data = useStaticQuery(
		graphql`
			query LayoutQuery {
				siteSettings: sanitySiteSettings {
					...LayoutFragment
				}
				meetTheTeam: allSanityExpert {
					nodes {
						_id
						_type
						title
						content: jobTitle
						image: thumbnail {
							asset {
								gatsbyImageData(width: 205, height: 200, placeholder: BLURRED)
							}
						}
						slug {
							current
						}
					}
				}
			}
		`
	);

	return (
		<>
			<GlobalStyles />
			<Typography />
			<Header
				logo={data.siteSettings.mainImage.asset}
				mobLogo={data.siteSettings.mainImageMob.asset}
				mainNav={data.siteSettings.mainNav}
				displayList={data.siteSettings.displayList}
				hamburgerMenu={data.siteSettings.mainNav}
			/>
			<MobileBanner className='mobile-banner' />
			<Popover
				id='enquiry-popover'
				isPopoverActive={isPopoverActive}
				activePopoverId={activePopoverId}
				togglePopover={togglePopover}
				showClose={true}
				placement='left'
				trigger={<FloatingButton className='get-in-touch-btn' value={'get in touch'} />}
				content={<EnquiryCard />}
			/>

			{children}
			<SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />

			<Separator color="grey-thick" />

			<SmallCardCarousel
				title={configData.team.text}
				content={configData.team.content}
				linkText="View All"
				link="/about/our-expert-team/"
				cardList={data.meetTheTeam.nodes}
			/>

			<Separator color='grey-thin' />

			<Cta 
				title={configData.enquiry.phone.alt_text}
				content={configData.enquiry.phone.content}
				link={configData.enquiry.enquiry.link}
				linkText={configData.enquiry.enquiry.text}
			/>
			<NewsletterCta
				title={configData.enquiry.newsletter.text}
				content={configData.enquiry.newsletter.content}
				ctaStyle="red"
			/>
			<Footer 
				logo={data.siteSettings.mainImage.asset}
				socialLinks={data.siteSettings.socialLinks}
				footerMenu={data.siteSettings.footerMenu}
				footerSecondaryMenu={data.siteSettings.footerSecondaryMenu}
			/>
		</>
	);
};

export default Layout;
