// Import Core
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// Import Components
import Button from '../../ui/buttons/Button';
// Import Utils
import { calculateOpeningTimes } from '../../../utils/datetime/calculateOpeningTimes';
// Import configData
import configData from '../../../../configData.json';

const MobileMenuBannerStyling = styled.div`
	display: none;
	@media (max-width: 992px) {
		display: flex;
		flex-direction: column;
		gap: 40px;
		text-transform: capitalize;
	}
	.banner {
		background-color: var(--white);
		padding-top: 12px;
		display: flex;
		/* justify-content: space-between; */
		flex-direction: column;
		/* align-items: center; */

		border-top: 1px solid var(--neutral-10);
		padding-top: 12px;

		gap: 8px;
		/* display: flex;
    align-items: center;
    gap: 32px; */

		.open {
			color: var(--red);
		}
		.links {
			display: flex;
			justify-content: space-between;

			a {
				color: var(--neutral-75);
			}
			.darwin {
				text-decoration: underline;
			}
		}
	}
`;

const MobileMenuBanner = ({ className }) => {
	const [displayOpen, setDisplayOpen] = useState('');
	useEffect(() => {
		calculateOpeningTimes(setDisplayOpen);
	}, []);

	return (
		<MobileMenuBannerStyling className={className}>
			<div className='banner-button'>
				{/* // ! FIX:  - make this all mobile banner compoinent */}
				<a href={configData.enquiry.phone.link}>
					<Button
						size='md'
						style='outline'
						className=''
						value={configData.enquiry.phone.alt_text}
						clickHandler={false}
						isDisabled={false}
					/>
				</a>
			</div>
			<div className='banner'>
				<div className='open small'>{displayOpen}</div>
				<div className='links'>
					{/* temporarily hiding this */}
					{/* <div className='darwin'>
						<Link to={configData.enquiry.darwin.link}>
							<p className='small'>{configData.enquiry.darwin.text}</p>
						</Link>
					</div> */}
					<div>
						<Link to={configData.enquiry.brochure.link}>
							<p className='small'>{configData.enquiry.brochure.text}</p>
						</Link>
					</div>
				</div>
			</div>
		</MobileMenuBannerStyling>
	);
};

export default MobileMenuBanner;
