// Import configData
import configData from '../../../configData.json';

export const calculateOpeningTimes = (setDisplayOpen) => {
	const currentDate = new Date();
	const currentDay = currentDate.getDay();
	const timestamp = currentDate.toLocaleTimeString('en-GB', { timeZone: 'Europe/London' });

	const getDay = (day, open, closed) => {
		if (timestamp >= open && timestamp <= closed) {
			let [hours, minutes, seconds] = closed.split(':');
			const hourToShow = hours % 12 || 12;
			const minutesToShow = minutes === '00' ? '' : `:${minutes}`;
			const ampm = hours < 12 ? 'am' : 'pm';
			const timeToShow = `${hourToShow}${minutesToShow}${ampm}`;

			setDisplayOpen(`Open today until ${timeToShow}`);
		} else {
			(day === 6 || day === 7)
				? setDisplayOpen(`Closed - reopens Monday 9am`)
				: setDisplayOpen(`Closed - reopens 9am`);
		}
	};

	if (currentDay >= 1 && currentDay <= 4) {
		const open = configData.opening_times.weekdays.open;
		const closed = configData.opening_times.weekdays.close;
		getDay(currentDay, open, closed);
	} else if (currentDay === 5) {
		const open = configData.opening_times.friday.open;
		const closed = configData.opening_times.friday.close;
		getDay(currentDay, open, closed);
	} else if (currentDay === 6) {
		const open = configData.opening_times.saturday.open;
		const closed = configData.opening_times.saturday.close;
		getDay(currentDay, open, closed);
	} else {
		const open = configData.opening_times.sunday.open;
		const closed = configData.opening_times.sunday.close;
		getDay(currentDay, open, closed);
	}
};
