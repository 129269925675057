// Import Core
import { createGlobalStyle } from "styled-components";
// Import Assets
import checkIcon from "../assets/icons/check.svg";

const GlobalStyles = createGlobalStyle`
:root {
    /**  COLORS  **/
    /* Red */
    --red: #B94C52;
    --red-light: #F5E4E5;
    --red-110: #A6444A;
    --red-75: #CA797D;
    --red-50: #DCA5A8;
    --red-25: #EED2D4;
    --red-10: #F8EDEE;
    /* Orange */
    --orange: #F9A551;
    --orange-light: #FFE7C1;
    --orange-120: #C78441;
    --orange-110: #E09449;
    --orange-75: #FBBC7D;
    --orange-50: #FBC996;
    --orange-25: #FEE8D4;
    --orange-10: #FEF6EE;
    /* Blue */
    --blue: #2D5C90;
    --blue-75: #6185AC;
    --blue-50: #96AEC7;
    --blue-25: #CAD6E3;
    --blue-10: #EAEFF4;
    /* Neutral */
    --black: #333333;
    --black-50 : #999;
    --neutral-75: #5C5C5C;
    --neutral-50: #999999;
    --neutral-25: #CCCCCC;
    --neutral-10: #EBEBEB;
    --neutral-5: #F5F5F5;
    /* Grey */
    --grey: #6E6E6E;
    --grey-75: #929292;
    --grey-50: #B7B7B7;
    --grey-25: #DBDBDB;
    --grey-10: #F0F0F0;

    --white: #FFFFFF;

    /** TYPOGRAPHY  **/
    --body-color: --black;

    /* Box Shadows */
    --box-shadow-xxsmall: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    --box-shadow-xsmall: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    --box-shadow-small: 0px 2px 4px -2px rgba(0, 0, 0, 0.06), 0px 4px 8px -2px rgba(0, 0, 0, 0.10);
    --box-shadow-medium: 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08);
    --box-shadow-large: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    --box-shadow-xlarge: 0px 24px 48px -12px rgba(0, 0, 0, 0.18);
    --box-shadow-xxlarge: 0px 32px 64px -12px rgba(0, 0, 0, 0.14);

    /* Transitions */
    --basic-transition: all 0.3s ease-in-out;
    --basic-transtion-linear: all 0.3s linear;

    /* Global Padding */
    --global-padding: 80px 64px;
    --global-padding-mobile: 40px 20px;

  }

  * {
    box-sizing: border-box;
  }

  /* Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px; /* for horizontal scrollbars */
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--neutral-5);
  }
  ::-webkit-scrollbar-track {
    background: var(--neutral-5);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 0;
    border: 3px solid var(--neutral-5);
  }

  html {
    background-color: var(--white);
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
  }
  body, #gatsby-focus-wrapper {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  main{
    flex:1;
  }

  p {
    margin: 0;
  }
  .tagline {
    color: var(--red);
  }

  address {
		font-style: initial;
	}

  hr {
		border: 1px solid var(--neutral-10);
		width: 100%;
	}

  blockquote {
    border-left: 2px solid var(--red);
    padding: 0 0 0 20px;
    margin: 36px 0;
    color: var(--red);
  }

  input[type="text"],  input[type="number"],  input[type="email"], input[type="tel"], textarea {
    color: var(--neutral-50);
    background-color: var(--grey-10);
    border: none;
    outline: none;
    &::placeholder {
      color: var(--neutral-50);
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: var(--neutral-50);
    }

    &:focus {
      outline: 1px solid var(--red);
    }
  }

  input[type="text"],input[type="number"], input[type="email"], input[type="tel"] {
    padding: 8px 12px 8px 20px;
    /* min-width: 365px; */
    max-width: 365px;
    width: 100%;
    height: 45px;
    @media (max-width: 768px) {
      min-width: 80%;
    }
  }
  textarea {
    padding: 16px 20px;
    /* min-width: 560px; */
    /* max-width: 560px; */
    width: 100%;
    min-height: 145px;
    @media (max-width: 768px) {
      min-width: 80%;
    }
  }

  .radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: red;
        border-radius: 100%;
        border: none;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: blue;
          /* box-shadow: inset 0 0 0 4px $color1; */
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: blue;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px red;
          border-color: darken(red, 25%);
          background: darken(red, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

#enquiry-popover {
  .popover-wrapper{
    .popover{
      min-width: 275px;
      background-color: var(--white);
      padding: 24px 20px 40px;
      box-shadow: var(--box-shadow-small);
      &:before {
        display: none;
      }
      &.popover-left {
        position: fixed;
        right: calc(40px + 16px);
        top: 50%;
        transform: translate(0, -50%);
      }
      .popover-content {
        color: var(--black);
        width: 100%;
        .popover-close {
          background: var(--blue);
          color: var(--white);
          &:hover {
            background: var(--blue-75);
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  border: none;
  outline: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  background-color: var(--grey-10);
  transition: var(--basic-transition);
  /* padding: 2px; */
  margin: 0;

  &::before {
    content: url(${checkIcon});
    display: block;
    padding: 0px 3px;
    height: 14px;
    width: 12px;
  }
  &:checked {
    background-color: var(--red);

    &:disabled {
      border-color: var(--grey-10);
      background-color: var(--grey-10);
    }

    &:disabled + span {
      color: var(--black);
    }

    /* &:focus {} */

  }
}

.error-msg {
  color: var(--red-110);
  font-size: inherit;
}

.page-max-width {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}


/* PERSON SINGLE WRAPPER STYLING */
/* Can this be person global? */
.person-main-content {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 120px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  position: relative;
  @media (max-width: 1100px) {
    padding: var(--global-padding);
  }
  @media (max-width: 992px) {
    margin: 0;
    max-width: 100%;
    &:after {
      content: '';
      background: linear-gradient(270deg, var(--white) 0%, rgba(255, 255, 255, 0.00) 100%);
      position: absolute;
      /* Calc height - width / 2 plus bottom padding */
      bottom: calc(calc(-100vw + 60px) / 2 + 40px);
      width: 60px;
      /* height: 100vw; */
      height: calc(100vw - 12px);
      transform: rotate(90deg);
    }
  }
  @media (max-width: 768px) {
    gap: 40px;
    padding: var(--global-padding-mobile);
  }
  .simple-content {
    padding: 0 0 64px 0;
    @media (max-width: 768px) {
      padding: 0 0 40px 0;
    }
  }
  .table {
  }
}

/* PRODUCT SINGLE */
.single-product-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;

	.product-main {
    padding: 64px;
		position: relative;
		display: flex;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		justify-content: space-between;
		align-items: flex-start;
		gap: 80px;
		@media (max-width: 768px) {
      padding: 32px 20px;
			flex-direction: column;
      gap: 40px;
		}
		.product-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 40px;
			flex: 1 0 0;
			width: 100%;
			padding: 0;
			> *, section {
				padding: unset;
			}
      /* Five Card Grid needs to be flex-wrap when on a product single to fit alongside cta */
      .five-card-grid {
        .cards {
          .card {
            flex-wrap: wrap;
          }
        }
      }

			.product-features {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 24px;
				@media (max-width: 768px) {
					gap: 20px;
				}
			}
			/* @media (max-width: 768px) {
				padding: 0 36px;
				width: unset;
			} */

      .product-pricing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
      }

      .table {
        height: unset;
        overflow: unset;
      }

		}

		.product-cta {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 40px;
			position: sticky;
			top: 200px;
			width: 375px;
			@media (max-width: 992px) {
				width: 40%;
			}
			@media (max-width: 768px) {
				/* width: calc(100% - 36px - 36px); */
				width: 100%;
				position: unset;
				display: flex;
				/* padding: 40px 24px; */
        padding: 0;
				flex-direction: column;
				align-items: flex-start;
				gap: 48px;
			}
			.cta-box {
				display: flex;
				padding: 40px 24px;
				flex-direction: column;
				align-items: flex-start;

				background: var(--white);

				gap: 24px;
				box-shadow: var(--box-shadow-small);
				/* width: inherit; */
				.cta-section-title {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 20px;
				}
				.person-blurb {
					display: flex;
					align-items: center;
					gap: 24px;
					width: 100%;
					@media (max-width: 768px) {
						flex-direction: column;
					}
					.person-pic {
						width: 80px;
						height: 80px;
						clip-path: circle();
					}
					.person-info {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						width: 100%;
					}
				}
				.person-link {
					margin-top: 16px;
					width: 100%;
					.person-btn {
						width: inherit;
						span {
							text-transform: capitalize;
						}
					}
				}
			}
			.enquiry-link {
				padding: 0 24px;
				width: 100%;
				.enquiry-btn {
					width: inherit;
				}
			}
		}

	}
	.anchor-link {
		scroll-margin-top: 100px;
	}
}
.single-product-page {
  .card-list {
    gap: 24px;
    .title {
      color: var(--black);
      font-size: var(--h3-font-size);
      line-height: 120%;
      text-transform: uppercase;
      @media (max-width: 575px) {
        font-size: var(--h3-mobile-font-size);
      }
    }
    .card-list-items {
      .card {
        .title {
          font-size: var(--h4-font-size);
          text-transform: capitalize;

          @media (max-width: 575px) {
            font-size: var(--h4-mobile-font-size);
          }
        }
      }
    }
  }
}

/* LOCATION SINGLE */
.location-sticky-wrapper {
		position: relative;
    width: 100%;
		margin: 0 auto;
    max-width: 1440px;

    display: flex;
    align-items: flex-start;
    padding: 80px 64px;
    gap: 32px;
		@media (max-width: 768px) {
			padding: 40px 20px 24px 20px;
      flex-direction: column;
      gap: 40px;
		}
		.location-main {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 40px;
			flex: 1 0 0;
			width: 100%;
			padding: 0 32px 0 0;
      border-right: 1px solid var(--neutral-10);
      @media (max-width: 768px) {
        border-right: none;
        padding: 0 0 32px 0;
        border-right: 1px solid var(--neutral-10);

      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        font-size: 20px;
        @media (max-width: 768px) {
          gap: 20px;
        }
        .section-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          @media (max-width: 768px) {
            gap: 12px;
          }
        }
        /* Keep formatting from sanity e.g. \n for newline */
				white-space: pre-line;
			}
			> *, section {
				padding: unset;
			}
		}

		.location-sticky {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 40px;
			position: sticky;
			top: 200px;
			width: 375px;
			@media (max-width: 992px) {
				width: 40%;
			}
			@media (max-width: 768px) {
				/* width: calc(100% - 36px - 36px); */
				width: 100%;
				position: unset;
				display: flex;
				/* padding: 40px 24px; */
        padding: 0;
				flex-direction: column;
				align-items: flex-start;
				gap: 32px;
			}

      .location-video {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid var(--neutral-10);
        .section-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          @media (max-width: 768px) {
            gap: 12px;
          }
        }
        .video-block {
          .video-controls {
            gap: 16px;
            .playPauseButton {
              height: 60px;
              width: 60px;
            }
          }
        }
      }
			.cta-box {
				display: flex;
				padding: 40px 24px;
				flex-direction: column;
				align-items: flex-start;

				background: var(--white);

				gap: 24px;
				box-shadow: var(--box-shadow-small);
				/* width: inherit; */
				.cta-section-title {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 20px;
				}
				.person-blurb {
					display: flex;
					align-items: center;
					gap: 24px;
					width: 100%;
					@media (max-width: 768px) {
						flex-direction: column;
					}
					.person-pic {
						width: 80px;
						height: 80px;
						clip-path: circle();
					}
					.person-info {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						width: 100%;
					}
				}
				.person-link {
					margin-top: 16px;
					width: 100%;
					.person-btn {
						width: inherit;
						span {
							text-transform: capitalize;
						}
					}
				}
			}

		}
  }


/* SLIDE IN MAP SECTION */
.explore {
	display: flex;
	/* max-width: 1440px; */
	flex-direction: column;
	align-items: center;

	position: relative;
	.section-title {
		padding: 64px 64px 0;
	}
	.slide-in-map-btn {
		position: sticky;
    display: block;
		top: 64px;
		right: 24px;
		margin-left: auto;
    @media (max-width: 992px) {
      display: none;
    }
	}
	&:has(.slide) {
		.slide-in-map-btn {
			display: none;
		}
	}
}

/* MAP */
#map-wrapper {
  width: 100%;
		margin-top: 64px;
		.slide-in-map {
			.slide-in-map-btn {
				display: none;
			}
		}
		.results-container {
				padding: 0 64px;
			}

		&.slide {
			.slide-in-map {
				.map {
					.slide-in-map-btn {
						display: flex;
						left: inherit;
						position: absolute;
						right: unset;
						top: -20px;
						/* because css to rotate button starts from left need half height of button minus 'min width' of button  */
						margin-left: calc(-124px + 24px);
					}
				}
			}
			.results-container {
				padding: 0 64px;
				padding: 0 32px 0 64px;
				margin-right: 32px;
				border-right: 1px solid var(--neutral-10);
				.card-list-simple {
					.card-list-items, a {
						.card {
							border-right: none;
							&:nth-child(even) {
								padding-left: unset;
								padding-right: unset;
							}
							&:before {
								border-bottom: none;
							}
						}
					}
          &.three-col {
            .card-list-items, a {
              .card {
                &:nth-child(even) {
                  padding-left: 32px;
                  padding-right: 32px;
                }
                &:nth-child(3n) {
                  border-left: 1px solid var(--neutral-10);
                  @media (max-width: 1200px) {
                    border-left: none;
                  }
                }
                @media (max-width: 1200px) {
                  border-right: none;
                }
              }
            }
          }
				}
			}
		}
	}


&#map-wrapper {
    .slide-in-map {
      .close-map-btn {
        display: none;
      }
    }

    &.slide {
      .slide-in-map {
        height: 40vh;
        .close-map-btn {
          display: block;
          position: absolute;
          z-index: 1;
          background-color: var(--primary);
          color: var(--white);
          border-radius: 50%;
          padding: 8px;
          margin: 10px;
        }
      }
    }
  }

  /** Slide in map */
  @media (min-width: 768px) {

    &#map-wrapper {
      display: flex;
      flex-direction: row-reverse;

      transition: all .25s ease-in-out;
        //cubic-bezier(0.75, -0.25, 0.25, .75);
      position: relative;
      transform: translateX(0);
      .results-container {
        width: 100%;
        margin-left: auto;
        transition: all 0.25s ease-in-out;
      }

      .slide-in-map {
        width: 0;
        transform: translateX(-0);
        transition: 0.25s ease-out all;

    .map {
      /* min-height: 328px;
      max-height: 350px; */
      height: inherit;
      > div {
        min-height: inherit;
      }
    }

    .map_marker {
      margin-top: -37px;
      height: 37px;
      width: 29px;
    }


      }
      &.slide {
        transform: translateX(-60vw) ;
        .results-container {
          width: calc(100% - 60vw);
          margin-left: auto;
        }
        @media (max-width: 768px) {
          transform: unset;
          .results-container {
            width: 100%;
          }
        }
        // Need to change layout of result cards when map is open
        .result-cards {
          padding: 64px 32px 64px 32px;
          .cards {
            display: flex;
            columns: 2;
            gap: 36px 64px;
            justify-content: flex-start;
            .card {
              flex-direction: column;
              gap: 0;
              width: calc(50% - 36px);
              height: auto!important;
              .slick-slider {
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                height: 200px!important;
                .slick-list {
                  .slick-track {
                    .slick-slide {
                      div {
                        .gatsby-image-wrapper {
                            height: 200px;
                        }
                      }
                    }
                  }
                }
                .slick-dots {
                    right: unset;
                    left: 8px;
                    bottom: 8px;
                }
              }
              .gatsby-image-wrapper {
                  height: 200px;
                  width: 100%;
                  min-height: 200px;
                  min-width: unset;
                  max-width: unset;
                  height: initial;
              }
              .card-body {
                  padding: 24px;
                  width: calc(100% - 48px);
                  .card_description {
                    flex: 1;
                  }
              }
            }
          }
        }

        .additional-cards {
          --grid-item--min-width: 200px;
          @media (max-width: 992px) {
            --grid-item--min-width: 200px;
          }
        }

        .slide-in-map {
          width: 60vw;
          /* height: 90vh; */
          height: calc(90vh - 80px);
          position: sticky;
          margin-right: -60vw;
          top: 107px;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    &#map-wrapper {
      &.slide {
        // Need to change layout of result cards when map is open
        .result-cards {
          padding: 64px 64px 64px 120px;
        }
      }
    }
  }

  /** Mobile Map */
  @media (max-width: 768px) {
    .explore {
      .section-title {
        padding: 40px 24px 0;
      }
      .slide-in-map-btn {
        display: none;
      }
      #map-wrapper {
        .results-container {
          padding: 20px 20px 0;
          margin-top: 20px;
          border-top: 1px solid var(--neutral-10);
          width: 100%;
          .card-list-items {
            width: 100%;
            flex-direction: row;
            gap: 20px;
            margin-bottom: 40px;
            .card {
              width: calc(50% - 20px);
            }
          }
        }
      }
    }

    &#map-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;



      .slide-in-map {
        /* height: 0; */
        height: 450px;
        width: 100vw;
        max-height: 450px;
        transition: all 0.25s ease-out;
        .map {
          /* min-height: 328px;
          max-height: 350px; */
          height: inherit;
          > div {
            min-height: inherit;
          }
        }

        .map_marker {
          margin-top: -37px;
          height: 37px;
          width: 29px;
        }
      }
    }
  }
`;

export default GlobalStyles;
