// Import Core
import React, { useState } from 'react';
// Import Styles
import Typography from '../styles/Typography';
import GlobalStyles from '../styles/GlobalStyles';

const AuthLayout = ({ children }) => {
	return (
		<>
			<GlobalStyles />
			<Typography />

      <header>Header</header>

      {children}

      <footer>Sign in footer</footer>
		</>
	);
};

export default AuthLayout;
