// Import Core
import React from 'react';
import styled from 'styled-components';

const IconInputStyling = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;

	input {
		padding-left: 48px;
	}

	svg {
		position: absolute;
		left: 12px;
		fill: var(--neutral-50);
		font-size: var(--body-font-size);
		height: 20px;
		width: 20px;
	}
	/* img {
  background-size: 16px 20px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2px 4px;
  width: 100%;
  height: 100%;
  display: block; */

	/* svg {
  position: absolute;
  left: 12px;
  width: 24px;
  height: 24px;
} */
`;

const IconInput = ({
	icon,
	placeholder,
	className,
	onChange = undefined,
	onKeyDown = undefined,
}) => {
	return (
		<IconInputStyling>
			{icon}
			<input
				type='text'
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
		</IconInputStyling>
	);
};

export default IconInput;
