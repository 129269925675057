// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Components
import { Link } from 'gatsby';
// Import configData
import configData from '../../../configData.json';

const EnquiryCardStyling = styled.div`
	h6 {
		margin-top: 12px;
	}
	hr {
		border: 1px solid var(--grey-25);
		margin-bottom: 24px;
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin: 0;
		list-style: none;
		padding: 0;
		li {
			a {
				color: inherit;
				text-decoration-line: underline;
			}
		}
	}
`;

const EnquiryCard = () => {
	return (
		<EnquiryCardStyling>
			<h6>GET IN TOUCH</h6>
			<hr />
			<ul>
				<li>
					<Link to={configData.enquiry.enquiry.link}>
						<p>{configData.enquiry.enquiry.text}</p>
					</Link>
				</li>
				<li>
					<a href={configData.enquiry.phone.link}>
						<p>{configData.enquiry.phone.text}</p>
					</a>
				</li>
				<li>
					<Link to={configData.enquiry.brochure.link}>
						<p>{configData.enquiry.brochure.text}</p>
					</Link>
				</li>
				<li>
					<Link to={configData.enquiry.newsletter.link}>
						<p>{configData.enquiry.newsletter.text}</p>
					</Link>
				</li>
			</ul>
		</EnquiryCardStyling>
	);
};

export default EnquiryCard;
