// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Icons
import { LiaTimesSolid } from 'react-icons/lia';

/* Styled Components */
const PopoverStyling = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	.popover-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		position: relative;

		.popover {
			align-items: center;
			background-color: var(--red);
			box-shadow: var(--box-shadow-small);
			display: flex;
			padding: 12px 24px;
			position: absolute;
			white-space: normal;
			z-index: 100;
			--popover-x-spacing: 15px;
			--popover-y-spacing: 45px;

			&::before {
				content: '';
				border: solid transparent;
				border-color: var(--red);
				border-width: 6px;
				height: 0;
				position: absolute;
				pointer-events: none;
				transform: rotate(45deg);
				width: 0;
				z-index: -1;
			}
			.popover-content {
				color: white;
				font-size: 16px;
				position: relative;
				width: 100%;

				display: flex;
				max-width: 250px;
				width: max-content;
				/* padding: 16px; */
				flex-direction: column;
				align-items: flex-start;
				gap: 4px;
				flex-flow: wrap;


				.popover-close {
					position: absolute;
					cursor: pointer;
					right: 0;
					height: 32px;
					width: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					svg {
						width: 15px;
						height: auto;
					}
				}
			}
			&.popover-top {
				top: calc(var(--popover-y-spacing, 50px) * -1);
				&::before {
					bottom: -5px;
					left: 50%;
					margin-left: -5px;
				}
			}

			&.popover-right {
				left: calc(100% + var(--popover-x-spacing, 40px));
				top: auto;
				&::before {
					left: 0;
					margin-left: -5px;
					top: calc(50% - 5px);
				}
			}

			&.popover-bottom {
				bottom: calc(var(--popover-y-spacing, 50px) * -1);
				&::before {
					left: 50%;
					margin-left: -5px;
					top: -5px;
				}
			}

			&.popover-left {
				right: calc(100% + var(--popover-x-spacing, 40px));
				top: auto;
				&::before {
					margin-right: -5px;
					right: 0;
					top: calc(50% - 5px);
				}
			}
		}
	}
`;
/* Render Component */
const Popover = ({
	isPopoverActive,
	activePopoverId,
	togglePopover,
	content,
	showClose = 'false',
	placement = 'top',
	id,
	trigger,
}) => {
	return (
		<PopoverStyling id={id} className="popover">
			<div className='popover-wrapper'>
				<div className='popover-children' onClick={() => togglePopover(id)}>
					{trigger}
				</div>

				{isPopoverActive && activePopoverId === id && (
					<div className={`popover popover-${placement}`}>
						<div className='popover-content'>
							{showClose && (
								<div className='popover-close' onClick={() => togglePopover(id)}>
									<LiaTimesSolid />
								</div>
							)}
							{content}
						</div>
					</div>
				)}
			</div>
		</PopoverStyling>
	);
};

export default Popover;
