// Import Core
import React from 'react';
import styled from 'styled-components';

const FloatingButtonStyling = styled.button`
	position: fixed;
	right: 0;
	top: calc(50% - 124px);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	z-index: 999;
	cursor: pointer;
	padding: 8px 20px 0;
	height: 40px;
	min-height: fit-content;
	min-width: 124px;
	width: fit-content;
	text-align: center;
	text-transform: uppercase;
	background-color: var(--white);
	color: var(--blue);
	border: 1px solid var(--blue);
	transform: rotate(-90deg) translateY(-50%);
	transform-origin: right;
	font-family: var(--font-family-heading);

	transition: var(--basic-transition);
	/* span {
		text-wrap: nowrap;
	} */

	&:hover {
		background: var(--blue-10);
	}
	&:active {
		background: var(--blue-25);
	}
	span {
		padding: 0;
		font-family: var(--font-family-heading);
	}
`;

const FloatingButton = ({ className, value, clickHandler }) => {
	return (
		<FloatingButtonStyling
			className={className}
			onClick={clickHandler ? clickHandler : undefined}>
			<span>{value}</span>
		</FloatingButtonStyling>
	);
};

export default FloatingButton;
