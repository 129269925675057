// Import Core
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// Import Components
import Navbar from './Navbar';
import NavbarBanner from './banners/NavbarBanner';
import Button from '../ui/buttons/Button';
// Import Utils
import useTablet from '../../utils/useTablet';
// Import configData
import configData from '../../../configData.json';

const HeaderStyling = styled.header`
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	background-color: var(--white);
	z-index: 999;
	.header {
		padding: 20px 64px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		max-width: 100vw;
		gap: 80px;
		width: 100%;
		@media (max-width: 992px) {
			border-bottom: 1px solid var(--neutral-10);
			padding: 16px 20px;
		}
		.logo {
			width: 200px;
			flex: 0 0 200px;
			@media (max-width: 575px) {
				width: 125px;
				flex: 0 0 125px;
			}
			img {
				height: 100px;
				width: auto;
				@media (max-width: 992px) {
					height: 50px;
				}
			}
		}
		.header-right {
			display: flex;
			flex-direction: column;
			align-items: end;
			gap: 20px;
			width: 500px;
			flex: 1 0 500px;
			.header-phone {
				button {
					span {
						text-transform: capitalize;
						font-family: var(--font-family-heading);
					}
				}
			}
			@media (max-width: 992px) {
				flex: unset;
				width: fit-content;
				.header-phone {
					display: none;
				}
			}
		}
	}
`;

const Header = ({ logo, mobLogo, mainNav, displayList }) => {
	const [isActiveNavItem, setIsActiveNavItem] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	const { isTablet } = useTablet();

	// FOLLOW UP: IS THIS REPEATED CODE?
	const handleClick = (index) => {
		if (isActiveNavItem) {
			if (activeIndex === index) {
				setActiveIndex(null);
				setIsActiveNavItem(false);
			} else {
				setActiveIndex(index);
			}
		} else {
			if (activeIndex === null) {
				setActiveIndex(index);
				setIsActiveNavItem(true);
			}
		}
	};

	return (
		<HeaderStyling>
			<NavbarBanner />
			<div className='header'>
				<Link to='/' className='logo'>
					<GatsbyImage
						image={isTablet ? getImage(mobLogo) : getImage(logo)}
						alt='Kirker Holidays'
					/>
				</Link>

				<div className='header-right'>
					<a href={configData.enquiry.phone.link} className='header-phone'>
						<Button
							size='md'
							btnStyle='outline'
							className=''
							value={configData.enquiry.phone.alt_text}
							clickHandler={false}
							isDisabled={false}
						/>
					</a>
					<Navbar
						menu={mainNav.itemsList}
						displayList={displayList}
						isActiveNavItem={isActiveNavItem}
						activeIndex={activeIndex}
						handleClick={handleClick}
					/>
				</div>
			</div>
		</HeaderStyling>
	);
};

export default Header;
