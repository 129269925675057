// Import Core
import { createGlobalStyle } from 'styled-components';
// Import Assets
// import '../assets/fonts/index.css';
import "../assets/fonts/typekitStyles.css";

const Typography = createGlobalStyle`
  :root {
    /** TYPOGRAPHY  **/
    // Body
    --font-family-base: "ltc-goudy-oldstyle-pro", serif;
    --font-family-base-italic: "ltc-goudy-oldstyle-pro", serif;
    --font-family-base-bold: "ltc-goudy-oldstyle-pro", serif;
    --font-family-base-bold-italic: "ltc-goudy-oldstyle-pro", serif;
    // Headings
    --font-family-heading: "trajan-pro-3", serif;
    --font-family-heading-alt: "ltc-goudy-oldstyle-pro", serif;
    
    --font-size-base: 18px; // will be 18px as specified in html

    --body-font-size: 0.889rem; //16px

    --h1-font-size: 2.667rem; //48px
    --h2-font-size: 2rem; //36px
    --h3-font-size: 1.333rem; //24px
    --h4-font-size: 1.167rem; //21px
    --h5-font-size: 1rem; //18px
    --h6-font-size: 0.889rem; //16px

    --h1-mobile-font-size: 1.778rem; //32px
    --h2-mobile-font-size: 1.333rem; //24px
    --h3-mobile-font-size: 1.167rem; //21px
    --h4-mobile-font-size: 1rem; //18px
    --h5-mobile-font-size: 0.944rem; //17px
    --h6-mobile-font-size: 0.667rem; //12px
  }

  html {
    font-family: var(--font-family-base), serif;
    font-size: var(--font-size-base);
    font-weight: 400;
    font-display: swap;
  }

  * {
    font-family: var(--font-family-base);
    font-size: var(--body-font-size);
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-style: normal;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-heading);
    font-weight: 400;
    &.alt {
      font-family: var(--font-family-base);
      font-weight: 700;
    }
  }
  h1 {
    font-size: var(--h1-font-size);
    line-height: 120%;
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: var(--h1-mobile-font-size);
    }
    &.alt {
      text-transform: capitalize;
    }
  }
  h2 {
    font-size: var(--h2-font-size);
    line-height: 120%;
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: var(--h2-mobile-font-size);
    }
    &.alt {
      text-transform: capitalize;
    }
  }
  h3 {
    font-size: var(--h3-font-size);
    line-height: 120%;
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: var(--h3-mobile-font-size);
    }
    &.alt {
      text-transform: capitalize;
    }
  }
  h4 {
    font-size: var(--h4-font-size);
    line-height: 130%;
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: var(--h4-mobile-font-size);
    }
    &.alt {
      text-transform: capitalize;
    }
  }
  h5 {
    font-size: var(--h5-font-size);
    font-family: var(--font-family-base);
    line-height: 140%;
    text-transform: capitalize;
    @media (max-width: 575px) {
      font-size: var(--h5-mobile-font-size);
    }
    &.alt {
      text-transform: capitalize;
    }
  }
  h6 {
    font-size: var(--h6-font-size);
    line-height: 140%;
    text-transform: capitalize;
    @media (max-width: 575px) {
      font-size: var(--h6-mobile-font-size);
    }
    &.alt {
      text-transform: capitalize;
      font-weight: 400;
    }
  }

  p, a, figcaption, span, td {
    font-family: var(--font-family-base);
    font-size: 0.889rem; //16px
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* To preserve line breaks from sanity text areas */
    white-space: pre-wrap;

    &.bold {
      font-weight: 700;
      font-family: var(--font-family-base-bold);
    }
    &.italic {
      font-style: italic;
      font-family: var(--font-family-base-italic);
    }
    &.bold.italic {
      font-family: var(--font-family-base-bold-italic);
    }

    &.large {
      font-size: 1.111rem; //20px
      @media (max-width: 575px) {
        font-size: 0.889rem; //16px
      }
    }
    &.medium {
      font-size: 1rem; //18px
    }
    &.small {
      font-size: 0.778rem; //14px
    }
    &.xsmall {
      font-size: 0.667rem; //12px
    }

    &.tagline {
      font-family: var(--font-family-base-italic);
      font-size: var(--body-font-size);
      font-style: italic;
      font-weight: 400;
      line-height: 130%;
      &.small {
        font-size: 0.778rem; //14px
        font-style: italic;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }
  input, textarea {
    font-family: var(--font-family-base);
    font-size: var(--body-font-size);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-overflow: ellipsis;
  }
  ul {
    &.large {
      * {
        font-size: 1.111rem; //20px
      }
    }
    &.medium {
      * {
        font-size: 1rem; //18px
      }
    }
    &.small {
      * {
        font-size: 0.778rem; //14px
      }
    }
    &.xsmall {
      * {
      font-size: 0.667rem; //12px
    }
  }
}
.red {
  color: var(--red);
}
.blue {
  color: var(--blue);
}

.short-excerpt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.medium-excerpt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--neutral-75);
}
`;

export default Typography;
