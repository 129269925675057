const getPagesFromIds = (pages, arrOfIds) => {
  // remove any duplicates if any exist
  arrOfIds = [...new Set(arrOfIds)];

  // -> Now we have arrOfIds - these are just ids so we need to get all hotel info by matching id
  const filteredResults = arrOfIds
    .map((id) => pages.length > 0 ? pages.filter((page) => page._id === id) : false).flat();

  return filteredResults
} 

export { getPagesFromIds }