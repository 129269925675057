// Import Core
import { useState, useEffect } from 'react';
// Import Assets
import greecePlaceholder from '../assets/images/watercolours/Acropolis_Athens_Greece.jpg';
import sicilyItalyPlaceholder from '../assets/images/watercolours/Agrigento_Sicily_Italy.jpg';
import francePlaceholder from '../assets/images/watercolours/Aix_En_Provence_Provence_France.jpg';
import granadaSpainPlaceholder from '../assets/images/watercolours/Alhambra_Palace_Granada_Spain.jpg';
import turkeyPlaceholder from '../assets/images/watercolours/Blue_Mosque_Istanbul_Turkey.jpg';
import spainPlaceholder from '../assets/images/watercolours/Casa_Batllo_Barcelona_Spain.jpg';
import loirValleyFrancePlaceholder from '../assets/images/watercolours/Chenonceau_Loire_Valley_France.jpg';
import czechRepublicPlaceholder from '../assets/images/watercolours/Charles_Bridge_Prague_Czech_Republic.jpg';
import florenceItalyPlaceholder from '../assets/images/watercolours/Florence_Italy.jpg';
import veniceItalyPlaceholder from '../assets/images/watercolours/Grand_Canal_Venice_Italy.jpg';
import portugalPlaceholder from '../assets/images/watercolours/Lisbon_Portugal.jpg';
import switzerlandPlaceholder from '../assets/images/watercolours/Lucerne_Switzerland.jpg';
import denmarkPlaceholder from '../assets/images/watercolours/Nyhavn_Copenhagen_Denmark.jpg';
import estoniaPlaceholder from '../assets/images/watercolours/Tallinn_Estonia.jpg';
import austriaPlaceholder from '../assets/images/watercolours/Vienna_State_Opera_Vienna_Austria.jpg';

/**
 * Images to use:
 * * Acropolis_Athens_Greece
 * * Agrigento_Sicily_Italy
 * * Aix en Provence_Provence_France
 * * Alhambra Palace_Granada_Spain
 * * Blue Mosque_Istanbul_Turkey
 * * Casa Batllo_Barcelona_Spain
 * * Chenonceau_Loir Valley_France
 * * Charles Bridge_Prague_Czech Republic
 * * Florence_Italy
 * * Grand Canal_Venice_Italy
 * * Lisbon_Portugal
 * * Lucerne_Switzerland
 * * Nyhavn_Copenhagen_Denmark
 * * Tallinn_Estonia
 * * Vienna State Opera_Vienna_Austria
 */



// FIX: Randomise array then pass in index, to get a random image if used in cards mapping etc, if no index then do usual as below???
 
export const usePlaceholderImage = () => {
  const [randomPlaceholderImage, setRandomPlaceholderImage] = useState(undefined);

  const getRandomPlaceholderImage = () => {
    var imageList = [
      greecePlaceholder,
      sicilyItalyPlaceholder,
      francePlaceholder,
      granadaSpainPlaceholder,
      turkeyPlaceholder,
      spainPlaceholder,
      loirValleyFrancePlaceholder,
      czechRepublicPlaceholder,
      florenceItalyPlaceholder,
      veniceItalyPlaceholder,
      portugalPlaceholder,
      switzerlandPlaceholder,
      denmarkPlaceholder,
      estoniaPlaceholder,
      austriaPlaceholder,
    ];
    var imageNumber = Math.floor(Math.random() * imageList.length); //gives you a random number in the range of imageList's size
    setRandomPlaceholderImage(imageList[imageNumber]);
  }

	useEffect(() => {
		getRandomPlaceholderImage();
	}, []);

	return { randomPlaceholderImage };
  }