// Import Core
import { useEffect, useState } from 'react';

const useTablet = () => {
	const [isTablet, setIsTablet] = useState(
		typeof window !== 'undefined' && window.innerWidth < 992
	);

	function handleResize() {
		setIsTablet(window.innerWidth < 992);
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			handleResize();
		}

		window.addEventListener('resize', handleResize);
		return () => {
			// remove event listener when the component is unmounted to not cause any memory leaks
			// otherwise the event listener will continue to be active
			window.removeEventListener('resize', handleResize);
		};
		// add `isTablet` state variable as a dependency so that
		// it is called every time the window is resized
	}, [isTablet]);

	return { isTablet, handleResize };
};

export default useTablet;
