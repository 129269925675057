import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// Import Libraries
import { Link } from 'gatsby';
// Import configData
import configData from '../../../../configData.json';
// Import Utils
import { calculateOpeningTimes } from '../../../utils/datetime/calculateOpeningTimes';

const NavbarBannerStyling = styled.div`
	height: 37px;
	background-color: var(--white);
	border-bottom: 1px solid var(--neutral-10);
	padding: 8px 64px;
	display: flex;
	justify-content: end;
	align-items: center;
	ul {
		display: flex;
		align-items: center;
		gap: 32px;
		li {
			a {
				color: var(--neutral-75);
			}
			&.darwin {
				text-decoration: underline;
			}
			&.open {
				color: var(--red);
			}
		}
	}

	@media (max-width: 992px) {
		display: none;
	}
`;

const NavbarBanner = () => {
	const [displayOpen, setDisplayOpen] = useState('');
	useEffect(() => {
		calculateOpeningTimes(setDisplayOpen);
	}, []);

	return (
		<NavbarBannerStyling>
			<ul>
				{/* FOLLOW UP: uncomment once concierge login is ready for live */}
				{/* <li className='darwin'>
					<Link to={configData.enquiry.darwin.link}>
						<p>{configData.enquiry.darwin.text}</p>
					</Link>
				</li> */}
				<li>
					<Link id="brochure-request-nav" to={configData.enquiry.brochure.link}>
						<p>{configData.enquiry.brochure.text}</p>
					</Link>
				</li>
				<li className='open'>{displayOpen}</li>
				{/* Temp to show easter opening times */}
				{/* <li><Link to="/easter-opening-times">Easter Opening Times</Link></li> */}
			</ul>
		</NavbarBannerStyling>
	);
};

export default NavbarBanner;
