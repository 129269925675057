// Import Core
import React from 'react';
import styled from 'styled-components';
// import { CSSTransition } from 'react-transition-group';

import { getLinks } from '../../../utils/getNavItemLinks';
import { Link } from 'gatsby';
import MenuSubMenuItem from './MenuSubMenuItem';

const NavItemStyling = styled.li`
	text-transform: uppercase;
	display: inline-block;
	vertical-align: top;
	transition: var(--basic-transition);
	a {
		white-space: nowrap;
		&:hover {
			color: var(--red);
		}
	}
	.nav-item{
		font-family: var(--font-family-heading);
	}

	/* CSS TRANSITIONS */
	.mega-menu-transition-enter {
		position: absolute;
		/* opacity: 0; */
		top: 0;
		left: 0;
		transform: translateY(-310px);
	}
	.mega-menu-transition-enter-active {
		width: 100%;
		/* opacity: 1; */
		transform: translate(0%);
		transition: all 300ms ease;
	}
	.mega-menu-transition-exit {
		position: absolute;
		left: 0;
		/* opacity: 1; */
		top: 0;
	}
	.mega-menu-transition-exit-active {
		width: 100%;
		transform: translateY(-350px);
		z-index: -1;
		transition: all 300ms ease;
		/* opacity: 0; */
	}
	.mobile-menu-list-item{
		a{
			font-family: var(--font-family-heading);
		}
}
`;

const NavItem = ({
	menuItem,
	isActiveNavItem,
	index,
	handleClick,
	activeIndex,
	closeHamburger
}) => {
	const singleLink = getLinks(menuItem.items[0]);
	return (
		<NavItemStyling key={index} className='nav-item'>
			{/* <a className='nav-link' onClick={() => handleClick(index)}>
				{menuItem.subMenuName}
			</a>

			<CSSTransition
				in={isActiveNavItem}
				unmountOnExit
				timeout={500}
				classNames='mega-menu-transition'>
				<div className='menu'>
					TODO:  In here if list of items
					Only show Mega Menu if index equals the activeIndex
					{activeIndex === index && <MegaMenu list={menuItem.items} />}
				</div>
			</CSSTransition> */}

			<div key={index}>
				{menuItem.items.length === 1 ? (
					<p className='mobile-menu-list-item'>
						{singleLink.type === 'internal' ? (
							<Link
								to={singleLink.link}
								onClick={handleClick}
							>{menuItem.subMenuName}</Link>
						) : (
							<a href={singleLink.link} target='_blank' rel='noreferrer' onClick={handleClick}>
								{menuItem.subMenuName}
							</a>
						)}
					</p>
				) : (
					<>
						{/* <CSSTransition
				in={isActiveNavItem}
				unmountOnExit
				timeout={500}
				classNames='mega-menu-transition'>
				<div className='menu'>
					TODO:  In here if list of items
					Only show Mega Menu if index equals the activeIndex
					{activeIndex === index && <MegaMenu list={menuItem.items} />}
				</div>
			</CSSTransition> */}

						<MenuSubMenuItem
							key={index}
							title={menuItem.subMenuName}
							items={menuItem}
							detailedSubMenu={menuItem.detailedSubMenu}
							sectionLink={menuItem.subMenuLink}
							sectionContent={menuItem.subMenuCaption}
							isActiveNavItem={isActiveNavItem}
							index={index}
							activeIndex={activeIndex}
							handleClick={handleClick}
							closeHamburger={closeHamburger}
							className='menu-list-item'
						/>
					</>
				)}
			</div>
		</NavItemStyling>
	);
};

export default NavItem;
