// Import Core
import React from 'react';
import styled from 'styled-components';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// Import Components
import NewsletterForm from '../forms/NewsletterForm';
// Impoprt Utils
import { usePlaceholderImage } from '../../../utils/useRandomPlaceholderImage';

/* Styled Components */
const NewsletterCtaStyling = styled.section`
	&.white {
		display: flex;
		justify-content: space-between;
		align-items: start;
		padding: var(--global-padding);
		background-color: var(--white);
		color: var(--neutral-75);
		gap: 80px;
		@media (max-width: 992px) {
			flex-direction: column-reverse;
			gap: 40px;
		}
		@media (max-width: 768px) {
			padding: var(--global-padding-mobile);
		}
		.cta-image {
		width: 40%;
		height: 400px;

		@media (max-width: 1100px) {
			width: 50%;
		}
		@media (max-width: 992px) {
			width: 100%;
			height: 300px;
		}
	

		@media (max-width: 768px) {
        width: 100%;
        height: 250px;
      }
		/* flex: 1 0 0;
      height: inherit;
      @media (max-width: 1100px) {
        width: 40%;
        flex: unset;
      }
      @media (max-width: 992px) {
        width: 50%;
      }
       */
      .gatsby-image-wrapper, img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: inherit;
      }
      .placeholder-img {
        object-fit: contain;
      }
    
	}
	.form {
			.content {
				display: flex;
				flex-direction: column;
				gap: 24px;
				width: 60%;
				@media (max-width: 1100px) {
					width: 50%;
				}
				@media (max-width: 992px) {
					width: 100%;
				}
				@media (max-width: 768px) {
					gap: 12px;
				}
			}
			@media (max-width: 768px) {
				.mobile-submit-btn {
						width: 100%;
					}
			}
		}
	}
	&.red {
		display: flex;
		justify-content: space-between;
		align-items: start;
		padding: var(--global-padding);
		background-color: var(--red);
		color: var(--white);
		gap: 80px;
		@media (max-width: 992px) {
			flex-direction: column;
			gap: 24px;
		}
		@media (max-width: 768px) {
			padding: var(--global-padding-mobile);
		}
		.content {
			display: flex;
			flex-direction: column;
			gap: 24px;
			width: 60%;
			@media (max-width: 1100px) {
				width: 50%;
			}
			@media (max-width: 992px) {
				width: 100%;
			}
			@media (max-width: 768px) {
				gap: 12px;
			}
		}
		.form {
			width: 40%;
			@media (max-width: 1100px) {
				width: 50%;
			}
			@media (max-width: 992px) {
				width: 100%;
			}
			@media (max-width: 768px) {
			}
			input[type='text'],
			input[type='email'] {
				color: var(--red);
				&::placeholder {
					color: var(--red);
					opacity: 1; /* Firefox */
				}

				&::-ms-input-placeholder {
					/* Edge 12 -18 */
					color: var(--red);
				}
			}
		}
	}
`;

/* Render Component */
const NewsletterCta = ({ title, content, image, ctaStyle }) => {
  const { randomPlaceholderImage} = usePlaceholderImage()

	return (
		<NewsletterCtaStyling className={ctaStyle} id='newsletterCta'>
			{ctaStyle === 'red' ? (
				<div className='content'>
					<h3 className='alt'>{title}</h3>
					<p>{content}</p>
				</div>
			) : (
				<div className='cta-image'>
					{image ? (
						<GatsbyImage image={getImage(image)} alt='Kirker Holidays' />
					) : (
						<img
							src={randomPlaceholderImage}
							alt=''
							className='placeholder-img'
						/>
					)}
				</div>
			)}
			<div className='form'>
				{ctaStyle === 'white' && (
					<div className='content'>
						<h3 className='alt'>{title}</h3>
						<p>{content}</p>
					</div>
				)}
				<NewsletterForm />
			</div>
		</NewsletterCtaStyling>
	);
};

export default NewsletterCta;
